import { computed } from "vue";
import store from "@/store/index";

/**
 * Returns applications
 * @returns array
 */
export const applications = computed(() => {
  return store.getters.currentApplications;
});
