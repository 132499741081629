
import { defineComponent } from "vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { authUser } from "@/core/helpers/users";

export default defineComponent({
  name: "topbar",
  components: {
    KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    return {
      authUser,
    };
  },
});
