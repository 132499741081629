
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  props: ["authUser"],
  components: {},
  setup() {
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "ar";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      ar: {
        flag: "media/flags/kuwait.svg",
        name: "Arabic",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => window.open("/#/login", "_self"));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      window.location.reload();
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });
    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
