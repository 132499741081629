
import { defineComponent } from "vue";
import { footerWidthFluid, locale } from "@/core/helpers/config";
import { appGlobalConfig } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
      appGlobalConfig,
      locale,
    };
  },
});
