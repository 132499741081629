import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}
const _hoisted_2 = { class: "text-dark order-2 order-md-1" }
const _hoisted_3 = { class: "text-muted fw-bold me-1" }
const _hoisted_4 = {
  href: "https://tahseelexpress.com/",
  target: "_blank",
  class: "text-gray-800 text-hover-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(new Date().getFullYear()) + "©", 1),
        _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.appGlobalConfig ? _ctx.appGlobalConfig[_ctx.locale()].title : ""), 1)
      ])
    ], 2)
  ]))
}