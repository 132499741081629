
import { defineComponent } from "vue";
import { authUser } from "@/core/helpers/users";

export default defineComponent({
  name: "kt-quick-links-menu",
  components: {},
  setup() {
    return {
      authUser,
    };
  },
});
