
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { applications } from "@/core/helpers/application";

export default defineComponent({
  name: "kt-menu",
  setup() {
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      applications,
    };
  },
});
