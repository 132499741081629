import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px",
  "data-kt-menu": "true"
}
const _hoisted_2 = {
  class: "d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10",
  style: {"background-color":"#4e046b"}
}
const _hoisted_3 = { class: "text-white fw-bold mb-3" }
const _hoisted_4 = { class: "row g-0" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_7 = { class: "fs-5 fw-bold text-gray-800 mb-0" }
const _hoisted_8 = { class: "fs-7 text-gray-400" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_11 = { class: "fs-5 fw-bold text-gray-800 mb-0" }
const _hoisted_12 = { class: "fs-7 text-gray-400" }
const _hoisted_13 = {
  key: 0,
  class: "col-6"
}
const _hoisted_14 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_15 = { class: "fs-5 fw-bold text-gray-800 mb-0" }
const _hoisted_16 = { class: "fs-7 text-gray-400" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "svg-icon svg-icon-3x svg-icon-success mb-2" }
const _hoisted_19 = { class: "fs-5 fw-bold text-gray-800 mb-0" }
const _hoisted_20 = { class: "fs-7 text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("app.quick_links")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: { name: 'create-invoices' },
          class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin011.svg" })
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("nav.invoices")), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("app.add_new")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          to: { name: 'create-employees' },
          class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/users/employees.svg" })
            ]),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("nav.employees")), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("app.add_new")), 1)
          ]),
          _: 1
        })
      ]),
      (_ctx.authUser.role == 'root')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_router_link, {
              to: { name: 'create-companies' },
              class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_14, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/companies/companies.svg" })
                ]),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("nav.companies")), 1),
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("app.add_new")), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_router_link, {
          to: { name: 'create-users' },
          class: "d-flex flex-column flex-center h-100 p-6 bg-hover-light"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_18, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/users/users.svg" })
            ]),
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("nav.users")), 1),
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("app.add_new")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}