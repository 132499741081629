import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-items-center d-lg-none me-1",
  title: "Show aside menu"
}
const _hoisted_2 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_aside_mobile_toggle"
}
const _hoisted_3 = { class: "svg-icon svg-icon-2x mt-1" }
const _hoisted_4 = { class: "d-flex align-items-center flex-grow-1 flex-lg-grow-0" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }
const _hoisted_7 = {
  class: "d-flex align-items-stretch",
  id: "kt_header_menu_nav"
}
const _hoisted_8 = { class: "d-flex align-items-stretch flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_header",
    class: _normalizeClass([[
      _ctx.headerTheme === 'light' && 'header-light',
      _ctx.headerTheme === 'dark' && 'header-dark',
    ], "header align-items-stretch"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs015.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: { name: 'home' },
          class: "d-lg-none"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.appGlobalConfig ? _ctx.appGlobalConfig.image.url : '',
              class: "h-50px logo w-50px"
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_KTMenu)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_KTTopbar)
        ])
      ])
    ], 2)
  ], 2))
}